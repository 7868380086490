import Vue from 'vue'
import VueRouter from 'vue-router'
import { addWxConfig } from '@/utils/wxConfig.js'
import setting from '@/setting.js'

// 解决当前路由跳转当前路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/:qrCode/:projectCode',
    name: 'authorize',
    component: () => import('../views/authorize')
  },
  {
    path: '/turntable',
    name: 'turntable',
    component: () =>
      import(/* webpackChunkName: "turntable" */ '../views/turntable/turntable')
  },
  {
    path: '/redEnvelopes',
    name: 'redEnvelopes',
    component: () =>
      import(
        /* webpackChunkName: "redEnvelopes" */ '../views/redEnvelopes/redEnvelopes'
      )
  },
  {
    path: '/codeState',
    name: 'codeState',
    component: () =>
      import(/* webpackChunkName: "codeState" */ '../views/codeState/codeState')
  },
  {
    path: '/qCodeState',
    name: 'qCodeState',
    component: () =>
      import(
        /* webpackChunkName: "qCodeState" */ '../views/codeState/qCodeState'
      )
  },
  {
    path: '/activityStatus',
    name: 'ActivityStatus',
    component: () =>
      import(
        /* webpackChunkName: "activityStatus" */ '../views/activity-status/activity-status'
      )
  },
  // {
  //   path: '/privacyAdult',
  //   name: 'PrivacyAdult',
  //   meta: {
  //     title: '隐私声明'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "privacy" */ '../views/privacy/adult.vue')
  // },
  // {
  //   path: '/privacyAdultNew',
  //   name: 'PrivacyAdultNew',
  //   meta: {
  //     title: '用户隐私政策'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "privacy" */ '../views/privacy/adultNew.vue')
  // },
  // {
  //   path: '/privacyChildren',
  //   name: 'PrivacyChildren',
  //   meta: {
  //     title: '隐私声明'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "privacy" */ '../views/privacy/children.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: '/' + process.env.VUE_APP_ROUTER_BASE,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || setting.title
  next()
})

let notWxConfigList = ['authorize']

router.afterEach(to => {
  if (notWxConfigList.indexOf(to.name) == -1) {
    addWxConfig()
  }
})

export default router
