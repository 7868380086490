<template>
  <div class="m-rule-cont">
 

  </div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.m-rule-cont {
  font-size: 24px;
  line-height: 36px;
  color: #313131;
  h3 {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    text-align: center;
    margin-bottom: 16px;
  }
  p {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    &.bold {
      font-weight: bold;
      margin-top: 5px;
    }
  }
}
</style>
